import { fetchPost, fetchGet, fetchPostPic } from '../index';
import axios from 'axios';
import { TypeClassItem } from '@/views/Approve/SalesRoleApprove/ProgramHome/types';
// 查询Dealer table数据接口
export const getVlList = (
  params = {
    retailStartDate: '',
    retailEndDate: '',
    bu: '',
    buId: '',
    xrate: '',
    flag: ''
  }
): Promise<any> => {
  return fetchGet(
    `/rvapi/report/vl/list?bu=${params.bu}&buId=${params.buId}&xrate=${params.xrate}&retailStartDate=${params.retailStartDate}&retailEndDate=${params.retailEndDate}&flag=${params.flag}`,
    params
  );
};
