
//http://localhost:8080/#/claim/EnquiryProgram
import { defineComponent, ref, reactive, toRefs, nextTick, computed } from 'vue';
import { getQueryList, deleteInfo } from '@/API/claim/claimQuery';
import { getVlList } from '@/API/salesReporting/VehicleLicenseTrackingReport';
import { getDealerList, getProgramList } from '@/API/salesReporting/claimCheckingReport';
import { message, TreeSelect } from 'ant-design-vue';
import { Modal } from 'ant-design-vue';
// import setColumnWidth from "@/utils/claim/setColumnWidth"
import downloadFile from '@/utils/claim/downloadFile';
import moment, { Moment } from 'moment';
import { useStore } from 'vuex';
import useFetch from '@/hooks/useFetch';
import { getUser } from '@/API/claim/delaerClaim/delaer';
import DM from '@/views/SalesReporting/components/downloadModal.vue';
// import axios from "axios";
const SHOW_PARENT = TreeSelect.SHOW_PARENT;
const tableWidth = window.innerWidth;
const tableWidth2 = (window.innerWidth * 4) / 9;
const tableWidth3 = (window.innerWidth * 5) / 7;
const sunCol1 = reactive([
  { title: 'Region', dataIndex: 'region', width: 100 },
  {
    title: 'Vehicle License Eligible Submission Rate %',
    dataIndex: 'rate',
    width: 200
  },
  { title: 'Eligible Retail No.', dataIndex: 'subCount', width: 200 },
  { title: 'Eligible Vehicle License No.', dataIndex: 'rstCount' }
  // { title: "Operation", dataIndex: "checkResult", width: 200, slots: { customRender: "operation" } },
]);
const sunCol2 = reactive([
  { title: 'Region', dataIndex: 'region', width: 100 },
  {
    title: 'Vehicle License Eligible Submission Rate>=X% Dealer Rate %',
    dataIndex: 'rate',
    width: 250
  },
  { title: 'Eligible Dealer No.', dataIndex: 'allCount', width: 200 },
  {
    title: 'Vehicle License Eligible Submission Rate>=X% Dealer No.',
    dataIndex: 'moreCount',
    width: 250
  },
  {
    title: 'Vehicle License Eligible Submission Rate<X% Dealer No.',
    dataIndex: 'lessCount'
  }
]);
const sunCol3 = reactive([
  { title: 'SubRegion', dataIndex: 'region', width: 100 },
  {
    title: 'Vehicle License Eligible Submission Rate %',
    dataIndex: 'rate',
    width: 200
  },
  { title: 'Eligible Retail No.', dataIndex: 'subCount', width: 200 },
  { title: 'Eligible Vehicle License No.', dataIndex: 'rstCount' }
]);
const sunCol4 = reactive([
  { title: 'SubRegion', dataIndex: 'region', width: 100 },
  {
    title: 'Vehicle License Eligible Submission Rate>=X% Dealer Rate %',
    dataIndex: 'rate',
    width: 250
  },
  { title: 'Eligible Dealer No.', dataIndex: 'allCount', width: 200 },
  {
    title: 'Vehicle License Eligible Submission Rate>=X% Dealer No.',
    dataIndex: 'moreCount',
    width: 250
  },
  {
    title: 'Vehicle License Eligible Submission Rate<X% Dealer No.',
    dataIndex: 'lessCount'
  }
  // { title: "Operation", dataIndex: "checkResult", width: 200, slots: { customRender: "operation" } },
]);
const rawDataCol = reactive([
  { title: 'Dealer Code (Cofico)', dataIndex: 'dealerCode', width: 200 },
  { title: 'Dealer Code (SWT)', dataIndex: 'dealerCodeSwt', width: 200 },
  { title: 'Dealer Name', dataIndex: 'dealerName', width: 200 },
  { title: 'POCC date', dataIndex: 'poccDate', width: 200 },
  { title: 'Dealer Group', dataIndex: 'dealerGroup', width: 200 },
  {
    title: 'Region',
    dataIndex: 'region',
    width: 200
  },
  { title: 'Region_CN', dataIndex: 'regionCn', width: 200 },
  { title: 'SubRegion', dataIndex: 'subRegionEn', width: 200 },
  { title: 'Province', dataIndex: 'provinceCn', width: 200 },
  { title: 'City', dataIndex: 'cityCn', width: 200 },
  { title: 'Year', dataIndex: 'year', width: 200 },
  { title: 'Month', dataIndex: 'month', width: 200 },
  {
    title: 'Vehicle License Eligible Submission Rate %',
    dataIndex: 'rate',
    width: 180
  },
  { title: 'Eligible Retail No.', dataIndex: 'subCount', width: 200 },
  {
    title: 'Eligible Vehicle License No.',
    dataIndex: 'rstCount',
    width: 200
  },
  {
    title: 'Ineligible Vehicle License No.',
    dataIndex: 'unPassCount',
    width: 160
  },
  {
    title: 'Final Result_If Eligible Submission',
    dataIndex: 'ifUseful',
    width: 200
  },
  {
    title: 'Special Notes（POCC exemption for X month）',
    dataIndex: 'poccFlag',
    width: 220
  }
]);
interface TreeDataItem {
  value: string;
  key: string;
  title?: string;
  disabled?: boolean;
  children?: TreeDataItem[];
}
const qmOptions: TreeDataItem[] = [
  {
    title: 'Q1',
    value: 'Q1',
    key: 'Q1',
    children: [
      {
        title: '01',
        value: '01',
        key: '01'
      },
      {
        title: '02',
        value: '02',
        key: '02'
      },
      {
        title: '03',
        value: '03',
        key: '03'
      }
    ]
  },
  {
    title: 'Q2',
    value: 'Q2',
    key: 'Q2',
    children: [
      {
        title: '04',
        value: '04',
        key: '04'
      },
      {
        title: '05',
        value: '05',
        key: '05'
      },
      {
        title: '06',
        value: '06',
        key: '06'
      }
    ]
  },
  {
    title: 'Q3',
    value: 'Q3',
    key: 'Q3',
    children: [
      {
        title: '07',
        value: '07',
        key: '07'
      },
      {
        title: '08',
        value: '08',
        key: '08'
      },
      {
        title: '09',
        value: '09',
        key: '09'
      }
    ]
  },
  {
    title: 'Q4',
    value: 'Q4',
    key: 'Q4',
    children: [
      {
        title: '10',
        value: '10',
        key: '10'
      },
      {
        title: '11',
        value: '11',
        key: '11'
      },
      {
        title: '12',
        value: '12',
        key: '12'
      }
    ]
  }
];

export default defineComponent({
  components: {
    DM
  },
  setup() {
    const { state } = useStore();
    const searchCon = ref();
    const tableHeight = ref();
    const tableHeight2 = ref();
    const tableBoxHeight = ref();
    const period = ref<Moment[]>([]);

    const inputPersent = ref<number>(95);

    //显示的表格数据
    const parameterList = ref<any>({
      bu: null,
      period: []
    });
    let buCopy = '';
    //获取bu选项
    const buList = computed(() =>
      state.user.buList
        .filter((bu: any) => {
          return bu.parentId === null;
        })
        .filter((bu: any) => {
          return bu.nameEn !== 'Companycar' && bu.nameEn !== 'CPO';
        })
    );
    const doGetBuList = () => {
      if (buList.value.length === 0) return;
      const mbIndex = buList.value.findIndex((i: any) => {
        return i.nameEn === 'MBPC';
      });
      const initIndex = mbIndex === -1 ? 0 : mbIndex;
      const value = buList.value[initIndex].nameEn;
      parameterList.value.bu = value;
      buCopy = value;
    };

    //重置
    const handlerReset = () => {
      parameterList.value.bu = buCopy;
      period.value = [];
      parameterDataLst.value.retailStartDate = '';
      parameterDataLst.value.retailEndDate = '';
    };

    //整理获取parameter
    const parameterDataLst = ref<any>({
      retailStartDate: '',
      retailEndDate: '',
      bu: '',
      buId: '',
      xrate: '',
      flag: ''
    });
    const dogetParameter = (flag: any) => {
      parameterDataLst.value.bu = parameterList.value.bu;
      buList.value.forEach((bui: any, buind: any) => {
        if (bui.nameEn === parameterList.value.bu) {
          parameterDataLst.value.buId = bui.id;
        }
      });

      parameterDataLst.value.xrate = inputPersent.value;
      parameterDataLst.value.flag = flag;
    };

    const periodChange = (val: Moment[]) => {
      console.log(val);
      parameterDataLst.value.retailStartDate = val[0];
      parameterDataLst.value.retailEndDate = val[1];
    };

    nextTick(() => {
      (document.getElementsByClassName('table-box')[0] as any).style.height =
        window.innerHeight - 180 - searchCon.value.$el.scrollHeight + 'px';
      const height = (document.getElementsByClassName('table-box')[0] as any).style.height;
      const heightNum = parseInt(height.substr(0, height.indexOf('px')));
      tableHeight.value = heightNum - 150;
      tableHeight2.value = heightNum - 190;
      tableBoxHeight.value = heightNum - 50;
    });
    const dealerByRegionData = ref<any[]>([]);
    const dealerBySubRegionData = ref<any[]>([]);
    const smtByRegionData = ref<any[]>([]);
    const smtBySubRegionData = ref<any[]>([]);
    const rowdataData = ref<any[]>([]);
    const doGetDealerData = () => {
      if (!parameterDataLst.value.retailStartDate || !parameterDataLst.value.retailEndDate) {
        message.error('Retail period (Year) must be selected!');
        return;
      }
      dogetParameter('');
      getVlList(parameterDataLst.value).then((res) => {
        dealerByRegionData.value = res.dealerByRegion;
        dealerBySubRegionData.value = res.dealerBySubRegion;
        smtByRegionData.value = res.smtByRegion;
        smtBySubRegionData.value = res.smtBySubRegion;
        rowdataData.value = res.rowdata;
      });
    };
    const dogetAllData = () => {
      doGetDealerData();
    };
    // dogetAllData()
    const initPage = () => {
      doGetBuList();
      // window.setTimeout(() => {
      //     dogetAllData();
      // }, 30);
    };
    initPage();
    //切换tab
    const tabN = ref<any>('Summary View');
    const tabChange = (e: any) => {
      if (e === '1') {
        tabN.value = 'Summary View';
      } else if (e === '2') {
        tabN.value = 'Raw Data';
      }
    };

    const downloadTableData = [
      {
        file: 'Raw Data',
        sign: 'raw'
      },
      {
        file: 'Report Data',
        sign: 'overview'
      }
    ];

    const downloadTypeVisible = ref(false);

    const download = () => {
      if (!parameterDataLst.value.retailStartDate || !parameterDataLst.value.retailEndDate) {
        message.error('Retail period (Year) must be selected!');
        return;
      }
      downloadTypeVisible.value = true;
    };
    const closeDownloadTypeModal = () => {
      downloadTypeVisible.value = false;
    };

    //下载
    const handlerDownload = (selectedData: any) => {
      dogetParameter('');
      let downloadType;
      if (selectedData.length > 1) {
        downloadType = 'zip';
      } else {
        downloadType = selectedData[0].sign;
      }
      parameterDataLst.value.flag = downloadType;
      const paramsConfig = {
        url: `/rvapi/report/vl/download/overall?retailStartDate=${parameterDataLst.value.retailStartDate}&bu=${parameterDataLst.value.bu}&buId=${parameterDataLst.value.buId}&xrate=${parameterDataLst.value.xrate}&retailEndDate=${parameterDataLst.value.retailEndDate}&flag=${parameterDataLst.value.flag}`,
        method: 'get'
        // params: parameterDataLst.value,
      };
      downloadFile(paramsConfig, 'application/vnd-excel; char-set=UTF-8').then(() => {
        downloadTypeVisible.value = false;
      });
    };

    return {
      download,
      downloadTypeVisible,
      closeDownloadTypeModal,
      downloadTableData,
      dealerByRegionData,
      dealerBySubRegionData,
      smtByRegionData,
      smtBySubRegionData,
      rowdataData,
      inputPersent,

      period,
      periodChange,
      tabN,
      tabChange,
      handlerDownload,
      dogetAllData,
      dogetParameter,
      buList,
      handlerReset,
      doGetBuList,
      qmOptions,
      parameterList,
      doGetDealerData,
      searchCon,
      tableWidth,
      tableWidth2,
      tableWidth3,
      tableBoxHeight,
      tableHeight,
      tableHeight2,
      sunCol1,
      sunCol2,
      sunCol3,
      sunCol4,
      rawDataCol,
      SHOW_PARENT
    };
  }
});
